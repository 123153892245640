import { gql } from "@apollo/client";
import { Intent, Placement } from "@blueprintjs/core";
import { DOCS_LINKS, OrgRole } from "@hex/common";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { DocsLink } from "../components/common/DocsLink";
import { Heading } from "../components/Heading";
import { HexButton, HexPopover, HexSwitch } from "../hex-components";
import { useUserForMagic } from "../hooks/magicHooks.js";
import { useCurrentUser } from "../hooks/me/useCurrentUser";
import { useUpdateOrgMagicSettings } from "../hooks/useUpdateOrgMagicSettings";
import { useUpdateUserSettings } from "../hooks/useUpdateUserSettings.js";
import { CyData } from "../util/cypress.js";

import { useUpdateHexMagicMutation } from "./MagicOnboardingPopover.generated";

const Wrapper = styled.div`
  max-width: 320px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  color: ${({ theme }) => theme.fontColor.MUTED};

  p {
    strong {
      color: ${({ theme }) => theme.fontColor.DEFAULT};
    }
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

gql`
  mutation UpdateHexMagic($hexMagic: Boolean!) {
    updateHexMagic(enabled: $hexMagic) {
      id
      hexMagic
    }
  }
`;

export const MagicOnboardingWrapper: React.ComponentType = React.memo(
  function MagicOnboardingWrapper() {
    const currentUser = useCurrentUser();
    const [updateHexMagic] = useUpdateHexMagicMutation();

    const setMagicOnboarded = useCallback(async () => {
      if (currentUser?.id) {
        await updateHexMagic({
          variables: { hexMagic: true },
          optimisticResponse: {
            __typename: "Mutation",
            updateHexMagic: {
              id: currentUser.id,
              __typename: "User",
              hexMagic: true,
            },
          },
        });
      }
    }, [currentUser, updateHexMagic]);

    return (
      <Wrapper>
        <Heading renderAs="h1" styleAs="h3">
          Hex Magic
        </Heading>
        <p>
          By accepting, you acknowledge that you agree to the{" "}
          <DocsLink to={DOCS_LINKS.MagicTerms}>Terms and Conditions</DocsLink>.
        </p>
        <HexButton
          data-cy={CyData.MAGIC_ACCEPT_POPOVER}
          intent={Intent.PRIMARY}
          onClick={setMagicOnboarded}
        >
          Accept
        </HexButton>
      </Wrapper>
    );
  },
);

/**
 * This is a new TOS for Magic that includes the updates needed to turn on
 * Typeahead. We should deprecate the old TOS and other wrapper once
 * we've officially turned on typeahead.
 */
export const MagicOnboardingWrapperNewTos: React.ComponentType<{
  userPreviouslyAcceptedTos: boolean;
}> = React.memo(function MagicOnboardingWrapperNewTos({
  userPreviouslyAcceptedTos,
}: {
  userPreviouslyAcceptedTos: boolean;
}) {
  const currentUser = useCurrentUser();
  const { updateMagicTypeaheadAndTos } = useUpdateUserSettings();

  const { updateOrgMagicTosAcceptedJuly2024 } = useUpdateOrgMagicSettings();
  const [isTypeaheadEnabled, setIsTypeaheadEnabled] = useState(true);

  const setMagicOnboarded = useCallback(async () => {
    if (currentUser?.id) {
      updateMagicTypeaheadAndTos(true, isTypeaheadEnabled);
    }
    // if admin accept TOS on behalf of all users
    if (currentUser?.orgRole === OrgRole.ADMIN) {
      updateOrgMagicTosAcceptedJuly2024(true);
    }
  }, [
    currentUser?.id,
    currentUser?.orgRole,
    isTypeaheadEnabled,
    updateMagicTypeaheadAndTos,
    updateOrgMagicTosAcceptedJuly2024,
  ]);

  // TODO: handle the actual edit callbacks here
  const handleTypeaheadToggle = useCallback(() => {
    setIsTypeaheadEnabled((prev) => !prev);
  }, []);

  const tosLanguage = userPreviouslyAcceptedTos ? (
    <>
      <p>
        We&apos;ve updated our Terms and Conditions. By accepting, you agree to
        the updated{" "}
        <DocsLink to={DOCS_LINKS.MagicTermsTypeahead}>
          Terms and Conditions
        </DocsLink>
        , including the use of{" "}
        <DocsLink to={DOCS_LINKS.MagicTypeahead}> Magic Typeahead</DocsLink>.
        You can disable Magic Typeahead below.
      </p>
    </>
  ) : (
    <p>
      By accepting, you acknowledge that you agree to the{" "}
      <DocsLink to={DOCS_LINKS.MagicTermsTypeahead}>
        Terms and Conditions
      </DocsLink>
      , including the use of{" "}
      <DocsLink to={DOCS_LINKS.MagicTypeahead}> Magic Typeahead</DocsLink>.
    </p>
  );

  return (
    <Wrapper>
      <Heading renderAs="h1" styleAs="h3">
        Hex Magic
      </Heading>
      {tosLanguage}
      <SwitchWrapper>
        <span>Magic Typeahead</span>
        <HexSwitch
          alignIndicator="right"
          checked={isTypeaheadEnabled}
          label={isTypeaheadEnabled ? "On" : "Off"}
          onChange={handleTypeaheadToggle}
        />
      </SwitchWrapper>
      <ButtonWrapper>
        <HexButton
          data-cy={CyData.MAGIC_ACCEPT_POPOVER}
          intent={Intent.PRIMARY}
          onClick={setMagicOnboarded}
        >
          Accept
        </HexButton>
      </ButtonWrapper>
    </Wrapper>
  );
});

export interface MagicOnboardingPopoverProps {
  children?: React.ReactNode;
  className?: string;
  placement?: Placement;
  fill?: boolean; // whether the popover should fill space or be inline
  isOpen?: boolean; // for controlled use only
  onClose?: () => void; // for controlled use only
}

export const MagicOnboardingPopover: React.ComponentType<MagicOnboardingPopoverProps> =
  React.memo(function MagicOnboardingPopover({
    children,
    className,
    fill,
    isOpen,
    onClose,
    placement,
  }: MagicOnboardingPopoverProps) {
    const {
      magicTypeaheadEnabled,
      onboardedToMagic,
      onboardedToMagicTypeahead,
    } = useUserForMagic();
    if (magicTypeaheadEnabled) {
      return (
        <HexPopover
          autoFocus={true}
          canEscapeKeyClose={true}
          className={className}
          content={
            <MagicOnboardingWrapperNewTos
              userPreviouslyAcceptedTos={onboardedToMagic}
            />
          }
          css={fill ? "flex: 1" : "display: inline"}
          disabled={onboardedToMagicTypeahead}
          enforceFocus={true}
          fill={true}
          isOpen={isOpen}
          minimal={true}
          placement={placement}
          usePortal={true}
          onClose={onClose}
        >
          {children}
        </HexPopover>
      );
    } else {
      return (
        <HexPopover
          autoFocus={true}
          canEscapeKeyClose={true}
          className={className}
          content={<MagicOnboardingWrapper />}
          css={fill ? "flex: 1" : "display: inline"}
          disabled={onboardedToMagic}
          enforceFocus={true}
          fill={true}
          isOpen={isOpen}
          minimal={true}
          placement={placement}
          usePortal={true}
          onClose={onClose}
        >
          {children}
        </HexPopover>
      );
    }
  });
